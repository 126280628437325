<template>
  <div class="page">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="information" service="mortgage" backlink="/mortgage/already-sold" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "s2p6_text" | localize }}</p>
          <form @submit.prevent>
            <div class="form__group">
              <v-text-field
                v-model="price"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @input="checkForm($v.price)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('price', price)"
                :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                :hint="
                  ($v.price.$dirty && !$v.price.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'c_s1p1_placeholder' | localize"
                append-icon="icon-₪"
                ref="price"
                outlined
              ></v-text-field>
            </div>
            <button
              type="button"
              @click="submitHandler"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage_old_price',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    price: mortgage.old_price || null
  }),
  validations: {
    price: { required }
  },
  created () {
    this.price = mortgage.old_price ? this.formatNumber(mortgage.old_price) : null
  },
  methods: {
    submitHandler () {
      this.price = this.parseNumber(this.price)
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }
      mortgage.old_price = this.price
      this.$cookies.set('mortgage', mortgage)
      this.$router.push('/mortgage/old-realty-mortgage')
    }
  }
}
</script>
